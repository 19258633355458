import { ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#EC0000",
    paper: "#FAFAFA",
  },
  primary: {
    main: "#EC0000",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#23779a",
    light: "#2e2e6d80",
    contrastText: "#ffffff",
  },
  error: {
    main: "#EC0000",
  },
  info: {
    main: "#23779a",
  },
  action: {
    active: "#F93549",
  },
  text: {
    primary: "#222222",
    secondary: "#00205C",
  },
};

export default palette;
