import { ThemeOptions } from "@mui/material";

const typography: ThemeOptions["typography"] = {
  fontFamily: ["Santander"].join(","),
  h1: {
    fontWeight: 400,
    fontSize: "3.0rem",
    lineHeight: "60px",
    letterSpacing: "-0.24px",
  },
  h2: {
    fontWeight: 500,
    fontSize: "2.625rem",
    lineHeight: "52px",
    letterSpacing: "-0.24px",
  },
  h3: {
    fontWeight: 700,
    fontSize: "1.57rem",
    lineHeight: "32px",
    letterSpacing: "-0.06px",
  },
  h4: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "32px",
    letterSpacing: "-0.06px",
  },
  h5: {
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "24px",
    letterSpacing: "-0.05px",
  },
  h6: {
    fontWeight: 700,
    fontSize: "1.125rem",
    lineHeight: "20px",
    letterSpacing: "-0.05px",
  },
  subtitle1: {
    fontWeight: 700,
    letterSpacing: "0.00938em",
  },
  subtitle2: {
    fontWeight: 700,
    letterSpacing: "0.00714em",
  },
  body1: {
    fontWeight: 400,
    letterSpacing: "0.00938em",
  },
  body2: {
    fontWeight: 400,
    letterSpacing: "0.01071em",
  },
  button: {
    fontWeight: 600,
    letterSpacing: "0.02857em",
    textTransform: "none",
  },
  caption: {
    fontWeight: 400,
    letterSpacing: "0.02857em",
  },
  overline: {
    fontWeight: 400,
    letterSpacing: "0.08333em",
    textTransform: "uppercase",
  },
};

export default typography;
