// import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Container, Link, Typography, Fade, useTheme } from "@mui/material";

import { IMAGES, STORE_BADGES } from "@APP/assets";
import CONFIG from "@APP/config";

const useStyles = makeStyles((theme) => ({
  versionLabel: {
    position: "absolute",
    bottom: 5,
    right: 12,
  },
}));

const HomeScreen = () => {
  const theme = useTheme();
  const classes = useStyles();
  // const { t } = useTranslation();

  return (
    <Fade in timeout={700}>
      <Container component="main" maxWidth="sm" id="homeScreenContainer">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          flexDirection="column"
          height="100vh"
          py="10vh"
          id="homeScreenBox"
          color={theme.palette.getContrastText(theme.palette.background.default)}>
          {/* <img
            src={IMAGES.APP_LOGO}
            width={220}
            alt={t("ProductName")}
            id="homeScreenProductImage"
          /> */}
          <Typography component="h1" style={{ fontSize: "40px" }} id="homeScreenProductName">
            My Business Manager
          </Typography>
          {CONFIG.ASSOCIATION.CO_OPERATIVE_BANK && (
            <img
              src={IMAGES.ASSOCIATION_LOGO_LIGHT}
              alt="The Co-operative Bank"
              id="homeScreenCoOpBank"
            />
          )}
          {(CONFIG.URLS.APP_STORE_URL || CONFIG.URLS.PLAY_STORE_URL) && (
            <Typography
              component="p"
              variant="subtitle1"
              align="center"
              id="homeScreenAppDownloadTypo">
              If you would like to be paid as easily please download our app and join our community
            </Typography>
          )}
          <Box my={2} textAlign="center">
            {CONFIG.URLS.OCM.REGISTRATION_URL && (
              <Typography variant="body1" id="homeScreenRegistrationTypo">
                Click{" "}
                <Link
                  href={CONFIG.URLS.OCM.REGISTRATION_URL}
                  target="_blank"
                  id="homeScreenRegistrationLink"
                  rel="noopener noreferrer"
                  underline="always"
                  color="inherit">
                  here
                </Link>{" "}
                to register.
              </Typography>
            )}
          </Box>
          {(CONFIG.URLS.APP_STORE_URL || CONFIG.URLS.PLAY_STORE_URL) && (
            <Box display="flex" alignItems="center">
              {CONFIG.URLS.APP_STORE_URL && (
                <Link
                  href={CONFIG.URLS.APP_STORE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  id="homeScreenAppStoreLink">
                  <img src={STORE_BADGES.APPLE_STORE} alt="Apple Store Icon" />
                </Link>
              )}
              {CONFIG.URLS.PLAY_STORE_URL && (
                <Link
                  href={CONFIG.URLS.PLAY_STORE_URL}
                  style={{ width: 154 }}
                  target="_blank"
                  id="homeScreenPlayStoreLink"
                  rel="noopener noreferrer">
                  <img src={STORE_BADGES.GOOGLE_PLAY_STORE} alt="Google Play Store Icon" />
                </Link>
              )}
            </Box>
          )}
          {CONFIG.URLS.TERMS_AND_CONDITIONS_URL ? (
            <Link
              href={CONFIG.URLS.TERMS_AND_CONDITIONS_URL}
              variant="caption"
              id="homeScreenTermmsAndConditionLink"
              color="inherit"
              underline="always">
              Terms and Conditions
            </Link>
          ) : null}
        </Box>
        <Typography
          variant="caption"
          color={theme.palette.getContrastText(theme.palette.background.default)}
          className={classes.versionLabel}
          id="versionNumber">{`v ${CONFIG.VERSION}`}</Typography>
      </Container>
    </Fade>
  );
};

export default HomeScreen;
